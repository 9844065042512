export interface AppCommonTip {
  id: string;
  user_id: string;
  organization_id: string;
  content: {
    message: string;
  };
  created_at: string;
}

export class AppCommonTip implements AppCommonTip {
  id: string;
  user_id: string;
  organization_id: string;
  content: {
    message: string;
  };
  created_at: string;

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.content = {
      message: "",
    };
    this.created_at = "";
  }
}