
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCommonTip} from "@/models/app/common/tip";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

@Options({
  props: [
    "tip",
  ],
  components: {
    ElDialog,
    ElButton,
    Edit,
  },
})
export default class AppPlayerTipsRootListFormLookupIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  tip: AppCommonTip = new AppCommonTip();
}
